import React from 'react';
import { GatsbyImage} from 'gatsby-plugin-image';
// @ts-ignore
import { EventDesc, Button,ImageContainer,Header, Blurb, Wrapper } from '../BaseHeroStyledComponents';
import { navigate } from 'gatsby';
import { ArrowLeft} from '../../../Svg/arrows';
import styled from 'styled-components';
import MyPortableText from '../../../MyPortableText';

const BackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EventHero = ({data}) => {
  const event = data.sanityPastEvents;

  return (
  <Wrapper>
     <button onClick={() => navigate(-1)}><BackDiv>{ArrowLeft}<h3><u>Back</u></h3></BackDiv></button>
    <Blurb>
      <p>{event.date}</p>
      <Header>{event.title}</Header>
      <EventDesc>
        <MyPortableText value={event._rawBody} />
      </EventDesc>
      {event.eventlink ? <Button url={event.eventlink}>Event Link</Button> : null}
    </Blurb>
    <ImageContainer>
      {!event.mainImage ? null : <GatsbyImage className={'main-image'} image={event.mainImage.asset.gatsbyImageData} alt ="Event Image"/>}
    </ImageContainer>
  </Wrapper>
  )
};

export default EventHero;