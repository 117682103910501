import React from 'react';
import { graphql } from 'gatsby';
import WebsiteDefaultPage from '../components/Page/Base';
import PastEventHero from '../components/PageSpecific/community/pastevents/PastEventHero';
import { JoinSection } from '../components/SharedSections';
import { Color } from '../styles';
import { LIGHT_DARK } from '../constants';
import { createGlobalStyle } from 'styled-components';

const GlobalStyleOverride = createGlobalStyle`
   #body {
      min-height: unset;
    }
`;

export const eventQuery = graphql`
query SingleEventQuery($id: String!) {
    sanityPastEvents(id: { eq: $id }) {
      title
      _rawBody
      day
      date
      eventlink
      mainImage {
        asset {
          gatsbyImageData(fit: FILL, placeholder: BLURRED)
          url
        }
      }
    }
  }
`;

const PastEventsPageTemplate = ({data}) => {
    return (
  <>
    <GlobalStyleOverride />
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <PastEventHero data = {data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  </>
    ); 
}

export default PastEventsPageTemplate;